<template>
  <div
    :id="isMyContainer"
    :style="{width: '100%', height: `${mapHeight}px`}"
  ></div>
</template>

<script>
import { MAP_ICON } from '@/utils/imageManage'

export default {
  name: 'Map',
  data () {
    return {
      map: {}
    }
  },
  props: {
    item: {
      type: Object
    },
    isMyContainer: {
      type: String
    },
    mapHeight: {
      type: Number,
      default: 180
    }
  },
  methods: {
    initMap ({ latitude, longitude, id }, isMyContainer) {
      // 定义地图中心点坐标
      // eslint-disable-next-line
      let center = new TMap.LatLng(latitude, longitude)
      // 定义map变量，调用 TMap.Map() 构造函数创建地图
      // eslint-disable-next-line
      let map = new TMap.Map(document.getElementById(isMyContainer), {
        center,
        zoom: 18.2,
        pitch: 43.5,
        rotation: 45,
        showControl: false
      })

      this.map = map
      this.addImgMarker(latitude, longitude, id)
    },
    addImgMarker (latitude, longitude, id) {
      // eslint-disable-next-line
      this.markerLayer = new TMap.MultiMarker({
        map: this.map,
        styles: {
          // eslint-disable-next-line
          myStyle: new TMap.MarkerStyle({
            width: 25,
            height: 25,
            src: MAP_ICON,
            anchor: { x: 16, y: 32 }
          })
        },
        geometries: [
          {
            id,
            styleId: 'myStyle',
            // eslint-disable-next-line
            position: new TMap.LatLng(latitude, longitude),
            content: ''
          }
        ]
      })
    }
  },
  mounted () {
    this.initMap(this.item, this.isMyContainer)
    // eslint-disable-next-line
    this.markerLayer = new TMap.MultiMarker({})
  }
}
</script>

<style scoped lang="less">
@import '~@/assets/css/common.less';
#isMyContainer {
  position: relative;
}
.m-dxby-map {
  .w(100%);
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 20px;
  padding: 12px 0;
  .m-dxby-map {
    .w(45%);
    min-height: 145px;
    margin-right: 15px;
    background-color: #000;
  }
  .m-dxby-map-info {
    .w(53%);
    h3, span {
      display: block;
    }
    h3 {
      color: #333;
      .fsize(16px);
      font-weight: bold;
      margin-bottom: 20px;
      padding-top: 5px;
    }
    span {
      .fsize(12px);
      color: #666;
      margin-bottom: 5px;
      i {
        font-weight: bold;
        .fsize(13px);
        .lht(20px);
        color: #333;
      }
    }
  }
}
</style>
