<template>
  <div class="container">
    <div class="container-con">
      <!-- Header S -->
      <Head
        :backIsShow="true"
      />
      <!-- Header E -->
      <!-- nav S -->
      <Nav/>
      <!-- nav E -->
      <!-- content S -->
      <section class="m-dxby-contact-content-wrap">
        <ul>
          <li
            v-for="(item, key) in mapArrData"
            :key="key"
          >
            <div class="m-dxby-map">
              <TenXunMap :item="item" :isMyContainer="`isMyContainer${key}`"/>
              <!--
                点击放大
                <img class="m-dxby-map-fullScreen" @click="showFullMap(item, key)" :src="mapFullScreen" alt="">
                -->
            </div>
            <div class="m-dxby-map-info">
              <h3>{{item.name}}</h3>
              <span>地址：<i>{{item.province}}{{item.city}}{{item.district}}{{item.address}}</i></span>
              <span>联系电话：<i style="color: #f084b1">{{item.contactsPhone}}</i></span>
              <span>微信：<i style="color: #f084b1">{{wxNumber ? wxNumber : '暂无'}}</i></span>
              <span>联系人：<i>{{item.contactsName}}</i></span>
            </div>
          </li>
        </ul>
      </section>
      <!-- content E -->
    </div>
    <!-- 地图放大弹框 S -->
    <!-- <Overlay
      :show="show"
      @click="show = false"
      :z-index="2000"
    >
      <div class="m-dxby-overlay-wrap">
        <div class="m-dxby-overlay-map">
          <TenXunMap
            :mapHeight="300"
            ref="showInitMap"
          />
        </div>
      </div>
    </Overlay> -->
    <!-- 地图放大弹框 E -->
    <Foot/>
  </div>
</template>

<script>
import axios from 'axios'
import Head from '@/components/Head'
import Nav from '@/components/Nav'
import Foot from '@/components/Foot'
import TenXunMap from '@/components/Map'
import metaLable from '@/utils/metaLable'
import { hostApi } from '@/utils/requestApi'
import { FULL_SCREEN_ICON } from '@/utils/imageManage'
import { wxNumber } from '@/utils/constantManage'
// import { Overlay } from 'vant'

export default {
  name: 'contacts',
  metaInfo: {
    title: '大新布业 - 联系我们',
    meta: metaLable
  },
  data () {
    return {
      wxNumber,
      mapArrData: [],
      show: false,
      mapFullScreen: FULL_SCREEN_ICON
    }
  },
  components: {
    Head,
    Nav,
    TenXunMap,
    Foot
    // Overlay
  },
  methods: {
    async getAdressData () {
      const res = await axios.get(`${hostApi}/address/list`)
      const { data } = res
      this.mapArrData = data
    }
    // showFullMap (mapItem, indexs) {
    //   this.show = true
    //   this.$refs.showInitMap.initMap(mapItem, `isMyContainer${indexs}`)
    // }
  },
  mounted () {
    this.getAdressData()
  }
}
</script>

<style scoped lang="less">
@import '~@/assets/css/common.less';
.m-dxby-contact-largeImg {
  img {
    width: 100%;
  }
}
.m-dxby-contact-content-wrap {
  padding: 0 24/2px;
  ul {
    li {
      .w(100%);
      display: flex;
      flex-wrap: nowrap;
      margin-bottom: 20px;
      padding: 12px 0;
      position: relative;
      .m-dxby-map {
        .wh(45%, 180px);
        margin-right: 15px;
        position: relative;
        .m-dxby-map-fullScreen {
          .w(35px);
          position: absolute;
          bottom: 0;
          right: 0;
          z-index: 2000;
          background: rgba(255, 255, 255, .5);
        }
      }
      .m-dxby-map-info {
        .w(53%);
        h3, span {
          display: block;
        }
        h3 {
          color: #333;
          .fsize(16px);
          font-weight: bold;
          margin-bottom: 20px;
          padding-top: 5px;
        }
        span {
          .fsize(12px);
          color: #666;
          margin-bottom: 5px;
          i {
            font-weight: bold;
            .fsize(13px);
            .lht(20px);
            color: #333;
          }
        }
      }
    }
  }
}
// 地图全屏
.m-dxby-overlay-wrap {
  .wh(100%, 100%);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  .m-dxby-overlay-map {
      .wh(100%, 350px);
      background-color: #fff;
  }
}
</style>
